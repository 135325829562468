import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useIsMobileModal } from 'features/modal'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'
import Layout from 'widgets/_layout'
import Menu from 'widgets/header/menu'
import { ROUTES } from 'shared/config/routes'
import { Logo } from 'shared/ui/logo'
import PhoneIcon from 'shared/assets/icons/PhoneIcon.webp'

import styles from './Header.module.scss'

export default function Header() {
  const { isMobile, isMobileModal, modals, setIsMobileModal, dispatch } =
    useIsMobileModal()

  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  return (
    <header
      className={classNames(
        styles.header,
        isMobileModal ? styles['header--fixed'] : null,
      )}
    >
      <Layout className={styles.layout}>
        <div className={styles.left_block}>
          <Logo />
          {!isMobile && !isRegisteredPage && <Menu />}
        </div>
        {!isMobile && !isRegisteredPage && (
          <div className={styles.right_block}>
            <div className={styles.call_info}>
              <img src={PhoneIcon} alt="PhoneIcon" />
              <p>бесплатно с мобильных операторов Армении</p>
            </div>
            <SubmitAffirmationModal btnProps={{ variant: 'variant2' }} />
          </div>
        )}
      </Layout>
    </header>
  )
}
