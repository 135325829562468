type resultsDataType = {
  id: number
  fullName: string
  transactionNumber: string
  transactionValue: string
  transactionTime: string
}

const arClients: resultsDataType[] = [
  {
    id: 1,
    fullName: 'Аветисян E.M.',
    transactionNumber: 'Дивиденды ***7321',
    transactionValue: '+85,123.45 AMD',
    transactionTime: '09:18',
  },
  {
    id: 2,
    fullName: 'Манукян O.B.',
    transactionNumber: 'Дивиденды ***4956',
    transactionValue: '+120,987.65 AMD',
    transactionTime: '14:37',
  },
  {
    id: 3,
    fullName: 'Симонян Н.У.',
    transactionNumber: 'Дивиденды ***1685',
    transactionValue: '+150,456.78 AMD',
    transactionTime: '10:45',
  },
  {
    id: 4,
    fullName: 'Абгарян А.М.',
    transactionNumber: 'Дивиденды ***2598',
    transactionValue: '+95,678.90 AMD',
    transactionTime: '16:20',
  },
  {
    id: 5,
    fullName: 'Геворкян З.Ж.',
    transactionNumber: 'Дивиденды ***6043',
    transactionValue: '+180,234.56 AMD',
    transactionTime: '12:03',
  },
  {
    id: 6,
    fullName: 'Гарибян А.К.',
    transactionNumber: 'Дивиденды ***7489',
    transactionValue: '+190,876.54 AMD',
    transactionTime: '11:29',
  },
  {
    id: 7,
    fullName: 'Арменян С.С.',
    transactionNumber: 'Дивиденды ***3712',
    transactionValue: '+70,432.10 AMD',
    transactionTime: '15:45',
  },
  {
    id: 8,
    fullName: 'Гагикян Д.Д.',
    transactionNumber: 'Дивиденды ***8260',
    transactionValue: '+75,678.90 AMD',
    transactionTime: '13:10',
  },
  {
    id: 9,
    fullName: 'Ашотян Я.Б.',
    transactionNumber: 'Дивиденды ***5137',
    transactionValue: '+130,123.45 AMD',
    transactionTime: '08:57',
  },
  {
    id: 10,
    fullName: 'Шагинян Р.О.',
    transactionNumber: 'Дивиденды ***1956',
    transactionValue: '+175,890.12 AMD',
    transactionTime: '17:12',
  },
  {
    id: 11,
    fullName: 'Эдуардян Ж.П.',
    transactionNumber: 'Дивиденды ***6743',
    transactionValue: '+76,543.21 AMD',
    transactionTime: '14:02',
  },
  {
    id: 12,
    fullName: 'Арманян Ш.А.',
    transactionNumber: 'Дивиденды ***3621',
    transactionValue: '+185,432.10 AMD',
    transactionTime: '10:35',
  },
  {
    id: 13,
    fullName: 'Сержан У.В.',
    transactionNumber: 'Дивиденды ***8074',
    transactionValue: '+110,987.65 AMD',
    transactionTime: '12:46',
  },
  {
    id: 14,
    fullName: 'Рубенян А.Ч.',
    transactionNumber: 'Дивиденды ***4298',
    transactionValue: '+140,654.32 AMD',
    transactionTime: '16:58',
  },
  {
    id: 15,
    fullName: 'Нерсисян Т.Б.',
    transactionNumber: 'Дивиденды ***7512',
    transactionValue: '+72,109.87 AMD',
    transactionTime: '09:23',
  },
  {
    id: 16,
    fullName: 'Гарегинян Д.У.',
    transactionNumber: 'Дивиденды ***5864',
    transactionValue: '+195,876.54 AMD',
    transactionTime: '11:55',
  },
  {
    id: 17,
    fullName: 'Арташян П.П.',
    transactionNumber: 'Дивиденды ***9430',
    transactionValue: '+78,901.23 AMD',
    transactionTime: '15:14',
  },
  {
    id: 18,
    fullName: 'Ашотян К.К.',
    transactionNumber: 'Дивиденды ***3102',
    transactionValue: '+160,234.56 AMD',
    transactionTime: '13:28',
  },
  {
    id: 19,
    fullName: 'Хачатурян Г.К.',
    transactionNumber: 'Дивиденды ***1786',
    transactionValue: '+74,879.41 AMD',
    transactionTime: '17:35',
  },
  {
    id: 20,
    fullName: 'Гарибян З.Ж.',
    transactionNumber: 'Дивиденды ***6975',
    transactionValue: '+72,345.67 AMD',
    transactionTime: '08:42',
  },
]

export const data = arClients
